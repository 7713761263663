.prices {
  font-family: Roboto, sans-serif;
  background-color: black;
  color: white;

  &Wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 24px 24px;

    @screen sm {
      padding: 20px 35px 32px 35px;
    }

    @screen md {
      max-width: 70%;
      margin: auto;
      padding: 20px 0 32px;
    }
  }

  &Container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &Image {
    margin-bottom: 30px;
    width: 100%;

    @screen sm {
      width: 80%;
    }
  }

  &Time {
    align-self: flex-start;
  }

  &Header {
    margin-bottom: 35px;
    font-family: Rubik, sans-serif;
    font-size: 30px;
    font-weight: 700;
    color: white;

    @screen sm {
      font-size: 40px;
    }
  }

  &Table {
    width: 100%;
    font-family: Roboto, sans-serif;
    color: white;
    text-align: center;
    margin-bottom: 10px;

    tbody td {
      padding-top: 20px;
      padding-bottom: 20px;

      @screen sm {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      &.noPadding {
        padding: 0;
      }

      span {
        display: inline-block;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    td,
    th {
      font-size: 0.45em;

      @screen sm {
        font-size: 0.65em;
      }

      @screen md {
        font-size: 1em;
      }

      @screen md+ {
        font-size: 1.2em;
      }

      @screen lg {
        font-size: 0.9em;
      }

      @screen 2xl {
        font-size: 1.2em;
      }

      &.orange {
        font-family: TekturNarrow, sans-serif;
        color: #f9b22e;
        font-size: 0.6em;

        @screen sm {
          font-size: 0.8em;
        }
        @screen md {
          font-size: 1.2em;
        }

        @screen lg {
          font-size: 1.8em;
        }

        @screen 2xl {
          font-size: 2.4em;
        }
      }
      border: 1px solid white;
      white-space: nowrap;

      &.pricesTableLogo {
        border: none;
        width: 25%;

        @screen sm {
          width: 35%;
        }
      }
    }
  }
}
