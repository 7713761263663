.about {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 55px 35px 55px 35px;
  background-color: black;
  background-image: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../assets/images/aboutBackground.jpeg");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 47.33% 50.2%;
  font-family: Rubik, sans-serif;
  text-align: center;
  color: white;
  line-height: 120%;

  @screen sm {
    flex-direction: row;
    justify-content: center;
    padding: 55px 0 55px 0;
  }

  &Container {
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    @screen md {
      width: 70%;
      flex-direction: row;
      gap: 30px;
    }
  }

  &Desc {
    text-align: left;
    width: 100%;
    display: flex;
    flex-direction: row;

    @screen sm {
      width: 50%;
      flex-direction: column;
    }

    &Header {
      text-transform: uppercase;
      font-weight: 700;
      line-height: 120%;
      font-size: 30px;
      margin-bottom: 40px;

      @screen sm {
        font-size: 40px;
      }
    }

    &Text {
      margin-bottom: 20px;
      display: none;
      line-height: 22px;
      font-size: 20px;

      @screen sm {
        display: block;
      }
    }

  }

  &List {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    @screen sm {
      width: 50%;
    }

    &Item {
      display: flex;
      flex-direction: row;
      height: 100px;
    }

    &Block {
      background-color: red;
      height: 100px;

      &Br {
        display: block;

        @screen md {
          display: none;
        }
      }

      &First {
        border: 2px solid #ff9418;
        border-right: none;
        width: 5%
      }

      &Second {
        font-family: Rubik, sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 2px solid #ff9418;
        border-left: none;
        flex: 1;
      }

      &Image {
        //align-self: center;
        //width: 80px;
        //height: 65px;
        //margin: 0 18px 0 12px;
        //background-image: url(../assets/images/icon-check.png);
        //background-repeat: no-repeat;
        //background-size: contain;
      }
    }
  }

}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  opacity: .6;
}