.computers {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: black;
  color: white;
  padding-bottom: 24px;

  @screen md {
    max-width: 70%;
    margin: auto;
  }

  &Header {
    width: 100%;
    display: block;
    font-family: Rubik, sans-serif;
    color: white;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 30px;
    text-align: center;

    @screen sm {
      font-size: 40px;
    }
  }

  &Group {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &Header {
      font-weight: 700;
      font-size: 24px;
    }

    &Grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(260px, auto));
      // grid-template-columns: repeat(1, 1fr);
      gap: 16px;
      width: 100%;
      padding: 24px;

      @screen sm {
        padding: 24px 0;
      }

      // @media (min-width: 640px) {
      //   grid-template-columns: repeat(2, 1fr);
      // }

      // @media (min-width: 768px) {
      //   grid-template-columns: repeat(3, 1fr);
      // }

      @media (min-width: 1440px) {
        grid-template-columns: repeat(4, 1fr);
      }

      &Item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        border: 1px solid #01550a;
        border-radius: 8px;
        padding: 8px;
        background-color: #01550a33;

        &Busy {
          border: 1px solid #3b71db;
          background-color: #3b71db33;
        }

        &Status {
          border: 1px solid #01550a;
          background-color: #01550a80;
          padding: 4px;
          border-radius: 8px;
          font-size: 14px;

          &Busy {
            border: 1px solid #3b71db;
            background-color: #3b71db80;
          }
        }

        &Title {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }
}

.button {
  padding: 4px 8px;
  // background-color: #ff9822;
  background: linear-gradient(
    90deg,
    rgba(142, 12, 12, 1) 0%,
    rgba(195, 0, 0, 1) 40%,
    rgba(255, 154, 2, 1) 100%
  );
  // color: #492516;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
}

.help {
  cursor: help;
}
