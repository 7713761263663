@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@font-face {
  font-family: "TTOCondBlack";
  src: local("TTOCondBlack"), url("assets/fonts/TTO.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TekturNarrow";
  src: local("TekturNarrow-Bold"),
    url("assets/fonts/TekturNarrow-Bold.ttf") format("truetype");
  font-style: bold;
}

.ant-btn-primary {
  background-color: #1677ff;
}

#root {
  background-color: black;
}
