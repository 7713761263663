.bootcamp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  font-family: Rubik, sans-serif;
  color: white;
  background-image: linear-gradient(to top,#000 0,rgba(0,0,0,.8) 90%,transparent 140%), url("../assets/images/photoBackground.avif");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  &Header {
    margin-bottom: 15px;
    font-weight: 700;
    font-size:30px;
    text-align: center;

    @screen sm {
      font-size: 40px;
    }
  }

  &Text {
    margin-bottom: 20px;
    max-width: 750px;
    padding: 0 10px;
    font-size: 20px;
    text-align: center;
  }

  &Swiper {
    display: flex;
    flex-direction: row;
    //height: 600px;
    width: 100%;

    @screen sm {
      width: 70%;
    }

    &Slide {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }


    &Image {
      width: 100%;
      max-height: 240px;
      object-fit: cover;
      //object-position: 24.2048% 50%;

      @screen sm {
        max-height: 600px;
      }
    }
  }

  &Photo {
    width: 100%;
    max-height: 600px;
    object-fit: cover;

    @screen sm {
      width: 70%;
    }
  }
}

.nav {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  position: absolute;
  top: 50%;
  z-index: 1;

  &Row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &Button {

    svg {
      width: 40px;
    }

    &Prev {
      transform: rotateY(180deg);
    }
  }
}