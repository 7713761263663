.header {
  background-color: black;

  &Wrap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-family: Rubik, sans-serif;
    font-size: 32px;
    padding: 20px calc(100vw * 0.07) 20px;

    @screen sm {
      justify-content: space-between;

      & > * {
        width: 33.333333%;
      }
    }

    @screen md {
      width: 70%;
      margin: auto;
      padding: 20px 0;
    }
  }

  &Logo {
    justify-self: center;
    width: 230px;
    display: block;
    object-fit: contain;

    @screen sm {
      width: 350px;
    }

    &Container {
      display: flex;
      justify-content: center;

      @screen 2xl {
        justify-content: flex-start;
      }
    }
  }

  &Address {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    gap: 8px;

    &Wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    &Icon {
      fill: #ff9418;
    }

    &Text {
      font-family: Rubick, sans-serif;
      font-size: 12px;
      display: flex;
      flex-direction: column;
    }
  }

  &Burger {
    position: fixed;
    background-color: black;
    z-index: 3;
    top: 24px;
    right: calc(100vw * 0.07);
    display: flex;
    gap: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;

    @screen sm {
      display: none;
    }

    > span {
      width: 50%;
      height: 2px;
      background-color: white;
    }
  }

  &Navigation {
    display: none;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1.2px;

    @screen sm {
      display: flex;
      justify-content: center;
    }

    &List {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      flex-wrap: wrap;
      list-style: none;
      row-gap: 40px;

      @screen sm {
        flex-direction: row;
        row-gap: unset;

        & > :not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
  &Social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    &Header {
      font-family: Rubik, sans-serif;
      font-size: 12px;
      color: white;
    }

    &Icons {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }

    &Icon {
      &:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: rgb(0, 119, 255);
      }
    }
  }
}

.lineBreak {
  flex-basis: 100%;
  height: 0;

  @screen sm {
    display: none;
  }
}

.modal {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  font-family: Rubik, sans-serif;
  font-weight: 700;
  letter-spacing: 1.2px;
  color: white;
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
}
